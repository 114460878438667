<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
// import { useModalStore } from '~/store/modal'

// Utils
import { getLocaleDirection } from '~/utils/helpers/language'

// Constants
import { DEV } from '~/utils/constants'

const i18n = useI18n()
const dir = computed(() => getLocaleDirection(i18n.locale))
const debug = ref(false)

const route = useRoute()
// const modalStore = useModalStore()

onMounted(() => {
  const dev = DEV
  debug.value = dev && route.query.debug === 'grid'
  history.scrollRestoration = 'manual'
})
</script>

<template>
  <main :dir="dir" class="site-wrapper">
    <GlobalHeader />
    <GlobalMenu />
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N9V2RHTQ"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <div class="site-body">
      <!-- <ClientOnly> -->
      <WebGLContainer />
      <!-- </ClientOnly> -->

      <div class="site-body-inner">
        <slot />
      </div>
    </div>

    <div id="super-footer" />

    <GlobalFooter />

    <div id="fullscreen-video-container" />
    <div id="global-modal" />
    <div id="hotspots" />

    <GlobalLoader />
    <FlyWithUsModal />

    <template v-if="debug">
      <Grid />
    </template>
  </main>
</template>

<style lang="scss">
@import '~/styles/utils/variables';

.site-wrapper {
  position: relative;
}

.site-body-inner {
  // margin-top: calc(var(--real-height, 100vh) * -1);
  margin-top: -100vh;
}

#fullscreen-video-container {
  position: fixed;
  z-index: map-get($z-layers, 'video');
}

#hotspots {
  position: relative;
  z-index: 1;
}
</style>
